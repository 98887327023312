import React from "react";
import Form from "../Form";
import "./index.css";
export default function PopUp({ active, setActive }) {
	return (
		<div className={active ? "popup-active popup" : "popup"}>
			<div className='container'>
				<div className='popup-header' onClick={() => setActive(false)}>
					<span>Х</span>
				</div>
				<div className='popup-body'>
					<Form />
				</div>
				<div className='popup-footer'></div>
			</div>
		</div>
	);
}
