import React from "react";
import Form from "../Form";
import "./index.css";
import Scroll from "../../img/scroll.png";
export default function FirstScreen() {
	return (
		<div className='main-first-screen'>
			<div className='green-mask'>
				<div className='col-12'>
					<h1>Еротичний масаж Львів</h1>
					<p className='white'>незабутній відпочинок для чоловіків</p>
				</div>
				<div className='container'>
					<Form />

					<p>
						<strong>Еромасаж</strong> - чуттєва процедура, яка допомагає
						відволіктися від повсякденних справ, турбот і проблем, покращує
						настрій і розслабляє. На відміну від звичайного медичного масажу він
						спрямований не стільки на опрацювання та зміцнення м'язів, скільки
						на отримання чоловіком задоволення.
					</p>
					<p>
						<strong>Еротичний масаж у Львові</strong> - максимально приємний
						відпочинок для будь-якого чоловіка. Прийшов час не боятися своїх
						бажань і розкрити нові грані сексуальності. Умілі майстрині масажу
						покажуть все, на що здатні.
					</p>
				</div>
				<div className='col-12'>
					<img src={Scroll} alt='bottom arrow' />
				</div>
			</div>
		</div>
	);
}
