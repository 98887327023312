import React from "react";
import "./index.css";

export default function Form() {
	return (
		<div className='form'>
			<form action='../send.php' method='post'>
				<label>
					Записатися на масаж
					<input type='tel' name='tel' required placeholder='Ваш номер телефону' />
				</label>
				<input type='submit' value='Надіслати' />
			</form>
		</div>
	);
}
