import React, { useState } from "react";
import PopUp from "../PopUp";

import "./index.css";
export default function Price() {
	const [modalActive, setModalActive] = useState(false);
	return (
		<div className='price' id='massage'>
			<div className='container' itemScope itemType='https://schema.org/Table'>
				<h3 itemProp='about'>Еротичний масаж ціна</h3>
				<table onClick={() => setModalActive(true)}>
					<thead>
						<tr>
							<th>Назва програми</th>
							<th>Тривалість</th>
							<th>Ціна</th>
							<th>Описання</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<b>Express</b>
							</td>
							<td>30хв</td>
							<td>1300грн</td>
							<td>
								Класичний масаж, боді масаж,можна торкатись все тіло дівчини
								один релакс
							</td>
						</tr>
						<tr>
							<td>
								<b>Standart</b>
							</td>
							<td>60хв</td>
							<td>1800грн</td>
							<td>
								Класичний масаж, еротичний масаж,можна торкатись і цілувати
								дівчину по тілу, крім інтимної зони, 2 релакси
							</td>
						</tr>
						<tr>
							<td>
								<b>Relax classic</b>
							</td>
							<td>60/90хв</td>
							<td>2000/3000грн</td>
							<td>
								Класичний масаж, еротичний масаж, можна торкатись всього тіла
								дівчини, кількість релаксацій не обмежена, спільний душ
							</td>
						</tr>
						<tr>
							<td>
								<b>Куні</b>
							</td>
							<td>60хв</td>
							<td>2500грн</td>
							<td>
								Класичний масаж всього тіла, еротичний масаж всьго тіла,
								кунілінгус, спільний душ, кількість релаксів не обмежена{" "}
							</td>
						</tr>
						<tr>
							<td>
								<b>Ексклюзив</b>
							</td>
							<td>90хв</td>
							<td>4500грн</td>
							<td>
								Класичний масаж, еротичний масаж, можна торкатись і цілувати все
								тіло дівчини, кунілінгус, дівчина купає вас в душі
							</td>
						</tr>
						<tr>
							<td>
								<b>Ексклюзив Aqua</b>
							</td>
							<td>60хв</td>
							<td>3700грн</td>
							<td>
								(масаж в джакузі) 30 хвилин класичний масаж на ліжку +1
								релаксація, можна торкатись тіла дівчини крім інтимної зони, 30
								хвилин в джакузі дівчина робить еротичну програму + одна
								релаксація!
							</td>
						</tr>
						<tr>
							<td>
								<b>Масаж з двома дівчатами</b>
							</td>
							<td>60хв</td>
							<td>4000грн</td>
							<td>
								Класичний масаж, еротичний масаж, можна торкатись і цілувати
								тілодівчат, спільний душ, лесбі шоу, піп шоу
							</td>
						</tr>
						<tr>
							<td>
								<b>Масаж для пари</b>
							</td>
							<td>60хв</td>
							<td>3500грн</td>
							<td>
								Дві дівчини виконують класичний масаж і еротичну програму,
								дівчат можна торкатися по тілу та цілувати, до оргазму доводять
								чоловіків, дівчатам роблять тількі еротичний масаж і класичний
							</td>
						</tr>
						<tr>
							<td>
								<b>Biп</b>
							</td>
							<td>120хв</td>
							<td>7500грн</td>
							<td>
								Класичний масаж, еротичний масаж, можна торкатись і цілувати все
								тіло дівчат, кунілінгус, дівчата купають вас в душі, кількість
								релаксації не обмежена, лесбі шоу + шампанське і цукерки в
								подарунок!
							</td>
						</tr>
						<tr>
							<td>
								<b>Урологічний масаж</b>
							</td>
							<td>60хв</td>
							<td>2000грн</td>
							<td>
								Один релакс, можна торкатись дівчину по тілу крім зони бікіні
							</td>
						</tr>
						<tr>
							<td>
								<b>Ніч Релаксу</b>
							</td>
							<td>480хв</td>
							<td>12000грн</td>
							<td>
								Можна забрати дівчину з собою, Класичний масаж, Боді масаж
							</td>
						</tr>
						<tr>
							<td>
								<b>Виїзд до клієнта</b>
							</td>
							<td>60хв/90хв</td>
							<td>2500/4000 гривень + таксі</td>
							<td>Ми надаємо тільки масажні послуги на території клієнта</td>
						</tr>
					</tbody>
				</table>
				<p>
					Доповнення можна взяти до будь-якої програми від 2000 гривень
					<ul>
						<li>Дівчина в чулках - 500 гривень;</li>
						<li>Розпущене волосся - 500 гривень;</li>
						<li>Фут-фетіш - 500 гривень еротичний масаж ніжками;</li>
						<li>
							Масаж лінгам - 500 гривень, масаж при якому особлива увага
							приділяється статевому органу;
						</li>
						<li>Душ до і після - 500 гривень;</li>
						<li>Лесбі шоу - 1000 гривень;</li>
						<li>Стріп шоу - 500 гривень дві пісні.</li>
					</ul>
				</p>
				<p>
					У нашому салоні кожен гість отримує:
					<ul>
						<li>можливість вибрати майстра на свій смак;</li>
						<li>
							по максимуму розслабитися після складного дня і зняти напругу;
						</li>
						<li>
							ніжні і чуттєві дотику, тепло бархатистою шкіри і наповнені
							переживаннями гри - ніколи ще близькість з красивою дівчиною не
							була такою хвилюючою;
						</li>
						<li>
							наповнений нотками чуттєвості і сексуальності масаж, занурює в
							світ справжнього задоволення;
						</li>
						<li>поєднання різних рухів для максимального задоволення;</li>
						<li>
							неквапливість і релакс - насолоджуйтеся масажем так довго, як
							захочеться
						</li>
					</ul>
				</p>
				<p>
					Головна мета дівчини - доставити чоловікові не тільки фізичне, а й
					емоційне, і навіть візуальну насолоду. Ніжні неквапливі ласки,
					комфортна і затишна обстановка, розслаблююча музика, всілякі аксесуари
					і засоби (масла, аромасвічки) - все це допоможе налаштуватися на
					потрібну хвилю. Апартаменти створені для того, щоб повністю
					розслаблятися і отримувати максимальний релакс - просторі дивани і
					ліжка, легка музика, зручні столи для масажу, затишне освітлення,
					комфортні душові.
				</p>
			</div>
			<PopUp active={modalActive} setActive={setModalActive} />
		</div>
	);
}
