import React, { useState } from "react";
import "./index.css";

export default function MainMenu() {
	const [status, setStatus] = useState(false);
	let menu = "menu";
	menu += status ? " activem" : " disacive";
	return (
		<div className='main-menu container-fluide'>
			<div className='col-12 col-xs-12 col-sm-6 col-md-3'>
				<span className='logotype'>P l a y</span>
			</div>
			<div className='col-12 col-xs-12 col-sm-12 col-md-5'>
				<button
					type='button'
					className='burger'
					onClick={() => setStatus(!status)}
				>
					☰
				</button>
				<nav role='navigation'>
					<ul className={menu} onClick={() => setStatus(false)}>
						<li>
							<a href='#girls'>Масажистки</a>
						</li>
						<li>
							<a href='#massage'>Програми</a>
						</li>
						<li>
							<a href='#order'>Забронювати</a>
						</li>
						<li className='colloredBoldText'>
							<a href='https://goo.gl/maps/2nuNKQ3kyWzUTjGm8'>Ми на мапі</a>
						</li>
					</ul>
				</nav>
			</div>
			<div className='col-12 col-xs-12 col-sm-12 col-md-4'>
				<ul className='main-phone'>
					<li>
						<a href='whatsapp://send?phone=+380986684173'>WhatsApp</a>
					</li>
					<li>
						<a href='viber://chat?number=380986684173'>Viber</a>
					</li>
					<li>
						<a href='tg://resolve?domain=Erotica_Lviv_34'>Telegram</a>
					</li>
					<li>
						<a href='tel:+380986684173'>+38 (098) 668 4173</a>
					</li>
				</ul>
			</div>
		</div>
	);
}
