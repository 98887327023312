import React, { useState } from "react";
import "./index.css";
import lady1 from "./ladies/leila1.jpg";
import lady2 from "./ladies/mila1.jpg";
import lady4 from "./ladies/yarina1.jpg";
import PopUp from "../PopUp";
export default function Ladies() {
	const [modalActive, setModalActive] = useState(false);
	return (
		<div className='ladies-screen' id='girls'>
			<div className='container'>
				<h2>Масаж для чоловіків</h2>
				<div className='ladies-block'>
				<div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
						<div className='lady' onClick={() => setModalActive(true)}>
							<figure>
								<img src={lady1} alt='еро масаж Львів' />
								<figcaption>Лейла</figcaption>
							</figure>
						</div>
					</div>
					<div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
						<div className='lady' onClick={() => setModalActive(true)}>
							<figure>
								<img src={lady2} alt='еротичний масаж у Львові' />
								<figcaption>Міла</figcaption>
							</figure>
						</div>
					</div>
					<div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
						<div className='lady' onClick={() => setModalActive(true)}>
							<figure>
								<img src={lady4} alt='еро масаж Львів' />
								<figcaption>Ярина</figcaption>
							</figure>
						</div>
					</div>
					<p>
						Щоб спробувати <strong>масаж Львів</strong>, досить просто прийти і
						вибрати майстриню і відповідну програму. Кожен гість отримує:
						<ul>
							<li>почуття незабутнього розслаблення;</li>
							<li>відпочинок душею і тілом;</li>
							<li>естетичну насолоду від краси дівчат;</li>
							<li>реалізацію найпотаємніших бажань (без статевого акту);</li>
							<li>
								індивідуальний підбір програм масажу в залежності від уподобань;
							</li>
							<li>використання класичних і незвичайних технік.</li>
						</ul>
						Руки умілих дівчат не пропустять жодну ерогенну зону на тілі
						клієнта. Кожен гість може керувати процесом або повністю віддатися у
						владу майстрині, дозволивши їй самостійно «досліджувати територію».
					</p>
				</div>
			</div>
			<PopUp active={modalActive} setActive={setModalActive} />
		</div>
	);
}
