import React from "react";
import Form from "../Form";
import "./index.css";

export default function Footer() {
	return (
		<footer>
			<div className='container' id='order'>
				<Form />
				<p>
					Можна вибрати експрес-програму або неспішний{" "}
					<strong>еротичний масаж</strong>. За бажанням чоловік може продовжити
					сеанс, щоб повною мірою насолодитися задоволенням. Кожен гість підбере
					для себе щось відповідно до свого бюджетом. Чоловічий салон стане для
					вас тим місцем, яке завжди приємно відвідати. Для гостей створена
					особлива атмосфера, працює бар з напоями, будь-які питання швидко
					вирішить адміністратор.
				</p>
			</div>
		</footer>
	);
}
